<template>
  <div>
    <div class="tips__main-container">
      <TipsFilter is-widget />
      <BetDataTable :blurred="hasCypheredData" />
      <TipsAnalysis is-widget />
    </div>
    <div class="button button-video is-rounded" @click="onClickComoFunciona">
      <span>¿Cómo funciona?</span>
      <span class="icon">
        <i class="mdi mdi-play-circle-outline"></i>
      </span>
    </div>
  </div>
</template>

<script>
import BetDataTable from '@/components/Sections/BetTool/TipsGolStats/BetDataTable';
import TipsFilter from '@/components/Menu/TipsFilter';
import TipsAnalysis from '@/components/Elements/TipsAnalysis';
import { clickComoFunciona } from '@/utils/analytics';

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'TipGolStats',
  components: {
    BetDataTable,
    TipsFilter,
    TipsAnalysis,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    hasCypheredData() {
      return !this.user.premiumAccount;
    },
  },
  methods: {
    ...mapActions('tips', ['fetchTournamentsPerTeam']),
    ...mapMutations('tips', ['setIsWidget']),
    ...mapMutations('general', ['setVideoModalVisibility', 'setVideoSrc']),
    onClickComoFunciona() {
      clickComoFunciona(this.getPremiumAccount, this.getIsGolstats);
      this.showVideo();
    },
    showVideo() {
      this.setVideoSrc('https://az740894.vo.msecnd.net/golstats-bets/tips.mp4');
      this.setVideoModalVisibility(true);
    },
  },
  mounted() {
    this.fetchTournamentsPerTeam({ teamId: 1 });
  },
  beforeMount() {
    this.setIsWidget(true);
  },
};
</script>

<style lang="scss" scoped>
.tips-golstats-filter {
  padding: 2rem 0;
}
.tips__main-container {
  padding: 1rem;
}
.button-video {
  margin: 0.5rem;
  color: white;
  background-color: #428ee6;
  font-size: 1rem;
  padding: 20px 30px;
  font-family: Circular-Std-Bold, sans-serif;

  .icon {
    margin-left: 10px !important;

    i {
      font-size: 1.5em;
    }
  }

  @media screen and (max-width: 494px) {
    font-size: 0.8rem;
    padding: 18px 25px;
  }

  @media screen and (max-width: 410px) {
    font-size: 1rem;
    padding: 20px 30px;
  }
}
</style>
